import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 24px;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;

  h1 {
    margin: 24px;
    font-weight: bold;
  }
`;

export const Transactions = styled.div`
  width: 1100px;
  height: 600px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 5px;
  padding: 16px;
  align-items: center;

  overflow-y: scroll;

  ::-webkit-scrollbar {
      width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #CCCACC;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #CCCACC;
  }
`;

export const Transaction = styled.div`
  display: flex;
  margin-top: 3px;
  font-size: 12px;

  .transaction-data {
    width: 1052px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    list-style: none;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .icons {
    button {
      margin-left: 32px;
      background: transparent;
      border: none;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 24px;

  button {
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 140px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 24px;
    text-decoration: none;
  }

  .print {
    margin-right: 60px;
    width: 180px;

    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .create {
    background: #091021;
    color: #ffff;
    border: 2px solid #091021;
    width: 160px;

    &:hover {
      background: #ffff;
      color: #091021;
    }
  }
`;
