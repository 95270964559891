import React from 'react';

import { MdSettings } from 'react-icons/md';

import { Container } from './styles';

function MenuBar() {
  return (
    <Container>
      <nav>
        <ul>
          <li>
            <a href="/cadastrar-cliente">Cadastrar Cliente</a>
          </li>
          <li>
            <a>Abrir</a>
            <ul>
              <li>
                <a href="/abrir-os-normal"> O.S. Normal</a>
              </li>
              <li>
                <a href="/abrir-os-retorno"> O.S. de Retorno</a>
              </li>
              <li>
                <a href="/abrir-pedido"> Pedido</a>
              </li>
              {/* <li><a href="/abrir-atendimento-externo"> Atendimento Externo</a></li> */}
            </ul>
          </li>
          <li>
            <a href="/consulta">Consulta</a>
          </li>
          <li>
            <a>Listar por Status</a>
            <ul className="status-list-ul">
              <li>
                <a href="/listar-os-normal">O.S. Normal</a>
              </li>
              <li>
                <a href="/listar-os-retorno">O.S. de Retorno</a>
              </li>
              <li>
                <a href="/listar-pedido">Pedidos</a>
              </li>
            </ul>
          </li>

          <li>
            <a>Caixa</a>
            <ul>
              <li>
                <a href="/caixa-hoje">Hoje</a>
              </li>
              <li>
                <a href="/caixa-periodo">Período</a>
              </li>
            </ul>
          </li>
          <a className="icon" href="/configuracoes">
            <MdSettings />
          </a>
        </ul>
      </nav>
    </Container>
  );
}

export default MenuBar;
