import TransactionTypes from './TransactionTypes';

const TYPES = {
  [TransactionTypes.INFLOW_UPFRONT_PAYMENT]: 'PAGAMENTO ADIANTADO',
  [TransactionTypes.INFLOW_COMPLETED_SERVICE_ORDER]: 'O.S. FINALIZADA',
  [TransactionTypes.INFLOW_SALE]: 'VENDA',
  [TransactionTypes.INFLOW_COMPLETED_ORDER]: 'PEDIDO FINALIZADO',
  [TransactionTypes.OUTFLOW_PURCHASE]: 'COMPRA',
  [TransactionTypes.NEUTRAL_CANCELED_SERVICE_ORDER]: 'O.S. CANCELADA',
  [TransactionTypes.NEUTRAL_CANCELED_ORDER]: 'PEDIDO CANCELADO',
};

export default function getTransactionTypeStringFromInt(typeInt) {
  try {
    const typeStr = TYPES[typeInt];
    return typeStr;
  } catch (err) {
    return '';
  }
}
