import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;

  h1 {
    margin: 24px;
    font-weight: bold;
  }
`;

export const FormsArea = styled.div`
  width: 895px;
  padding: 0 24px 24px 24px;

  align-items: center;
  justify-content: center;

  border-radius: 10px;
  border: 2px solid #091021;


  form {
    width: 895px;

    align-items: center;
    justify-content: center;

    input {
      border: none;
      padding: 2px 8px;
    }

    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
    }

    .search button {
      margin-top: 50px;
    }

    .client p {
      font-weight: bold;
      margin-top: 24px;
    }

    .client input {
      width: 400px;
      margin-right: 16px;
    }

    > button {
      margin-top: 50px;
    }

    .order-data {
      margin-top: 24px;
      border-top: 2px solid #091021;
      padding-top: 24px;
    }

    .order-data strong {
      font-weight: bold;
      font-size: 24px;
    }

    .order-data p {
      margin-top: 16px;
    }

    .order-data input {
      border: none;
      padding: 2px 8px;
    }

    .order-data .name-code-model {
      display: flex;
      flex-direction: row;
    }

    .order-data .value-button {
      display: flex;
      flex-direction: row;
    }

    .order-data button {
      margin-left: 546px;
      margin-top: 46px;
    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;

      margin-left: 16px;


      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;

export const Clients = styled.div`

`;

export const Client = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:16px;

  .client {
    width: 600px;
  }

  .client strong {
    font-weight: bold;
    font-size: 20px;
  }

   .select-button {
      background: transparent;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #091021;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 2px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;


      &:hover {
        background: #091021;
        color: #ffff;
      }
    }
`;

export const SelectedClient = styled.div`
  width: 600px;
  margin-top: 16px;

  strong {
    font-size: 24px;
    font-weight: bold;
  }
`;
