import React, { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import { Container, LoginHeader, Content } from './styles';
import toast from 'react-hot-toast';

function Login() {
  const { signIn } = useAuth();

  const history = useNavigate();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    username: Yup.string().required('Usuário obrigatório'),
    password: Yup.string().required('Senha obrigatória'),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        await schema.validate(data, { abortEarly: false });

        await signIn({
          username: data.username,
          password: data.password,
        });

        history('/consulta');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        toast.error('Ocorreu um erro ao fazer login. Cheque suas credenciais');
      }
    },
    [history, signIn],
  );

  return (
    <Container>
      <LoginHeader>
        <h1>Sistema Eletro Rádio Mark</h1>
      </LoginHeader>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Login</h1>
          <Input type="text" name="username" placeholder="Usuário" />
          <Input type="password" name="password" placeholder="Senha" />
          <button type="submit">Entrar</button>
        </Form>
      </Content>
    </Container>
  );
}

export default Login;
