import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  FormsArea,
  Clients,
  Client,
  SelectedClient,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import api from '../../services/api';
import toast from 'react-hot-toast';

function OrderRegistration() {
  const formRefSearch = useRef(null);
  const formRefOrder = useRef(null);
  const history = useNavigate();

  const [clients, setClients] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showClient, setShowClient] = useState(false);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    cpf: '',
    cnpj: '',
    name: '',
    email: '',
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    phone1: '',
    phone2: '',
    phone3: '',
    created_at: '',
    updated_at: '',
  });

  const handleSelectClient = useCallback(client => {
    setSelectedClient({
      id: client.id,
      cpf: client.cpf,
      cnpj: client.cnpj,
      name: client.name,
      email: client.email,
      zip_code: client.zip_code,
      address: client.address,
      number: client.number,
      complement: client.complement,
      neighborhood: client.neighborhood,
      city: client.city,
      state: client.state,
      phone1: client.phone1,
      phone2: client.phone2,
      phone3: client.phone3,
      created_at: client.created_at,
      updated_at: client.updated_at,
    });
    setShowClient(true);
  }, []);

  const handleSearch = useCallback(async data => {
    setShowClient(false);
    setHasSearchedBefore(true);
    try {
      formRefSearch.current.setErrors({});

      const schema = Yup.object().shape({
        search: Yup.string().required('Digite algo para buscar'),
      });

      await schema.validate(data, { abortEarly: false });

      const { search } = data;

      const response = await api.get('/clients', {
        params: {
          filter: search,
        },
      });

      const clientsData = response.data;

      setClients(clientsData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRefSearch.current.setErrors(errors);

        // return
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRefOrder.current.setErrors({});

        const schema = Yup.object().shape({
          piece_name: Yup.string().required('Digite algo para buscar'),
        });

        await schema.validate(data, { abortEarly: false });

        setShowButton(false);

        const response = await api.post('/orders', {
          client_id: selectedClient.id,
          piece_name: data.piece_name,
          piece_code: data.piece_code,
          piece_value: data.piece_value,
          product_model: data.product_model,
        });

        const newOrder = response.data;

        toast.success('Pedido cadastrado com sucesso!');

        history(`/pedido/${newOrder.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefOrder.current.setErrors(errors);

          return;
        }

        toast.error('Ocorreu um erro ao criar o pedido. Cheque os dados.');
      }
    },
    [history, selectedClient.id],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Abrir Pedido</h1>

        <FormsArea>
          <Form ref={formRefSearch} onSubmit={handleSearch}>
            <div className="search">
              <div className="client">
                <p>Nome do Cliente, CPF ou CNPJ</p>
                <Input
                  name="search"
                  containerStyle={{
                    width: '280px',
                  }}
                  type="text"
                />
              </div>
              <button type="submit">Buscar</button>
            </div>
          </Form>

          {!showClient && clients.length > 0 ? (
            <Clients>
              {clients.map(client => (
                <Client key={client.id}>
                  <div className="client">
                    <strong>{client.name}</strong>
                    {client.cpf && <p>CPF: {client.cpf || '-'}</p>}
                    {client.cnpj && <p>CNPJ: {client.cnpj || '-'}</p>}
                  </div>
                  <button
                    className="select-button"
                    onClick={() => handleSelectClient(client)}
                    type="button"
                  >
                    Selecionar
                  </button>
                </Client>
              ))}
            </Clients>
          ) : (
            !showClient && hasSearchedBefore && <p>Nenhum cliente encontrado</p>
          )}

          {showClient && (
            <SelectedClient>
              <strong>{selectedClient.name}</strong>
              <p>
                <b>CPF:</b> {selectedClient.cpf || '-'}
              </p>
              <p>
                <b>CNPJ:</b> {selectedClient.cnpj || '-'}
              </p>
              <br />
              <p>
                <b>Contato:</b>
              </p>
              <p>E-mail: {selectedClient.email || '-'}</p>
              <p>
                Telefones: {selectedClient.phone1 || '-'} /{' '}
                {selectedClient.phone2 || '-'} / {selectedClient.phone3 || '-'}
              </p>
              <br />

              <p>
                <b>Endereço:</b>
              </p>
              <p> CEP: {selectedClient.zip_code || '-'} </p>
              <p> Rua: {selectedClient.address || '-'}</p>
              <p> Número: {selectedClient.number || '-'}</p>
              <p> Bairro: {selectedClient.neighborhood || '-'}</p>
              <p> Complemento: {selectedClient.complement || '-'}</p>
            </SelectedClient>
          )}

          <Form ref={formRefOrder} onSubmit={handleSubmit}>
            <section className="order-data">
              <strong>Informações do Pedido</strong>

              <div className="name-code-model">
                <div className="name">
                  <p>Nome da peça</p>
                  <Input
                    type="text"
                    name="piece_name"
                    containerStyle={{
                      width: '350px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="code">
                  <p>Código</p>
                  <Input
                    type="text"
                    name="piece_code"
                    containerStyle={{
                      width: '300px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="model">
                  <p>Modelo do produto</p>
                  <Input
                    type="text"
                    name="product_model"
                    inputStyle={{
                      width: '134px',
                    }}
                    containerStyle={{
                      width: '150px',
                      marginRight: '16px',
                    }}
                  />
                </div>
              </div>

              <div className="value-button">
                <div className="value">
                  <p>Valor</p>
                  <Input
                    type="text"
                    name="piece_value"
                    containerStyle={{
                      marginRight: '16px',
                      marginBottom: '24px',
                    }}
                  />
                </div>
                {showButton && <button type="submit">Salvar</button>}
              </div>
            </section>
          </Form>
        </FormsArea>
      </Content>
    </Container>
  );
}

export default OrderRegistration;
