import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Login from '../pages/Login';
import Search from '../pages/Search';
import ListNormalOS from '../pages/List OS Normal';
import ListReturnedOS from '../pages/List OS Retorno';
import ClientRegistration from '../pages/Client Registration';
import NormalOSRegistration from '../pages/Normal OS Registration';
import ReturnedOSRegistration from '../pages/Returned OS Registration';
import ClientUpdate from '../pages/Update Client';
import OS from '../pages/OS';
import Order from '../pages/Order';
import UpdateNormalOS from '../pages/Update Normal OS';
import TodayCashRegister from '../pages/Today Cash Register';
import PeriodCashRegister from '../pages/Period Cash Register';
import UpdateReturnedOS from '../pages/Update Returned OS';
import Configuration from '../pages/Configuration';
import OrderRegistration from '../pages/Order Registration';
import UpdateOrder from '../pages/Update Order';
import ListOrders from '../pages/List Orders';
import ExternalServiceRegistration from '../pages/External Service Registration';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />

        {/* <Route isPrivate path="/consulta" component={Search} /> */}
        <Route exact path="/consulta" element={<PrivateRoute />}>
          <Route exact path="/consulta" element={<Search />} />
        </Route>

        {/* <Route isPrivate path="/listar-os-normal" component={ListNormalOS} /> */}
        <Route exact path="/listar-os-normal" element={<PrivateRoute />}>
          <Route exact path="/listar-os-normal" element={<ListNormalOS />} />
        </Route>

        {/* <Route isPrivate path="/listar-os-retorno" component={ListReturnedOS} /> */}
        <Route exact path="/listar-os-retorno" element={<PrivateRoute />}>
          <Route exact path="/listar-os-retorno" element={<ListReturnedOS />} />
        </Route>

        {/* <Route isPrivate path="/listar-pedido" component={ListOrders} /> */}
        <Route exact path="/listar-pedido" element={<PrivateRoute />}>
          <Route exact path="/listar-pedido" element={<ListOrders />} />
        </Route>

        {/* <Route
          isPrivate
          path="/cadastrar-cliente"
          component={ClientRegistration}
        /> */}
        <Route exact path="/cadastrar-cliente" element={<PrivateRoute />}>
          <Route
            exact
            path="/cadastrar-cliente"
            element={<ClientRegistration />}
          />
        </Route>

        {/* <Route isPrivate path="/editar-cliente/:id" component={ClientUpdate} /> */}
        <Route exact path="/editar-cliente/:id" element={<PrivateRoute />}>
          <Route exact path="/editar-cliente/:id" element={<ClientUpdate />} />
        </Route>

        {/* <Route
          isPrivate
          path="/abrir-os-normal"
          component={NormalOSRegistration}
        /> */}
        <Route exact path="/abrir-os-normal" element={<PrivateRoute />}>
          <Route
            exact
            path="/abrir-os-normal"
            element={<NormalOSRegistration />}
          />
        </Route>

        {/* <Route
          isPrivate
          path="/abrir-os-retorno"
          component={ReturnedOSRegistration}
        /> */}
        <Route exact path="/abrir-os-retorno" element={<PrivateRoute />}>
          <Route
            exact
            path="/abrir-os-retorno"
            element={<ReturnedOSRegistration />}
          />
        </Route>

        {/* <Route isPrivate path="/abrir-pedido" component={OrderRegistration} /> */}
        <Route exact path="/abrir-pedido" element={<PrivateRoute />}>
          <Route exact path="/abrir-pedido" element={<OrderRegistration />} />
        </Route>

        {/* <Route
          isPrivate
          path="/abrir-atendimento-externo"
          component={ExternalServiceRegistration}
        /> */}
        <Route
          exact
          path="/abrir-atendimento-externo"
          element={<PrivateRoute />}
        >
          <Route
            exact
            path="/abrir-atendimento-externo"
            element={<ExternalServiceRegistration />}
          />
        </Route>

        {/* <Route isPrivate path="/os/:id" component={OS} /> */}
        <Route exact path="/os/:id" element={<PrivateRoute />}>
          <Route exact path="/os/:id" element={<OS />} />
        </Route>

        {/* <Route isPrivate path="/pedido/:id" component={Order} /> */}
        <Route exact path="/pedido/:id" element={<PrivateRoute />}>
          <Route exact path="/pedido/:id" element={<Order />} />
        </Route>

        {/* <Route isPrivate path="/editar-os/:id" component={UpdateNormalOS} /> */}
        <Route exact path="/editar-os/:id" element={<PrivateRoute />}>
          <Route exact path="/editar-os/:id" element={<UpdateNormalOS />} />
        </Route>

        {/* <Route
          isPrivate
          path="/editar-os-retorno/:id"
          component={UpdateReturnedOS}
        /> */}
        <Route exact path="/editar-os-retorno/:id" element={<PrivateRoute />}>
          <Route
            exact
            path="/editar-os-retorno/:id"
            element={<UpdateReturnedOS />}
          />
        </Route>

        {/* <Route isPrivate path="/editar-pedido/:id" component={UpdateOrder} /> */}
        <Route exact path="/editar-pedido/:id" element={<PrivateRoute />}>
          <Route exact path="/editar-pedido/:id" element={<UpdateOrder />} />
        </Route>

        {/* <Route isPrivate path="/caixa-hoje" component={TodayCashRegister} /> */}
        <Route exact path="/caixa-hoje" element={<PrivateRoute />}>
          <Route exact path="/caixa-hoje" element={<TodayCashRegister />} />
        </Route>

        {/* <Route isPrivate path="/caixa-periodo" component={PeriodCashRegister} /> */}
        <Route exact path="/caixa-periodo" element={<PrivateRoute />}>
          <Route exact path="/caixa-periodo" element={<PeriodCashRegister />} />
        </Route>

        {/* <Route isPrivate path="/configuracoes" component={Configuration} /> */}
        <Route exact path="/configuracoes" element={<PrivateRoute />}>
          <Route exact path="/configuracoes" element={<Configuration />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
