const USER_AUTHORITY_LEVELS = {
  100: 'GERENTE',
  200: 'FUNCIONÁRIO(A)',
};

export default function isManager(authority_level) {
  try {
    const isManagerBool = USER_AUTHORITY_LEVELS[authority_level] === 'GERENTE';
    return isManagerBool;
  } catch (err) {
    return false;
  }
}
