const TransactionTypes = Object.freeze({
  // INFLOW (100 - 199)
  INFLOW_UPFRONT_PAYMENT: 101,
  INFLOW_COMPLETED_SERVICE_ORDER: 102,
  INFLOW_SALE: 103,
  INFLOW_COMPLETED_ORDER: 104,

  // OUTFLOW (200 - 299)
  OUTFLOW_PURCHASE: 201,

  // NEUTRAL (300 - 399)
  NEUTRAL_CANCELED_SERVICE_ORDER: 301,
  NEUTRAL_CANCELED_ORDER: 302,
});

export default TransactionTypes;
