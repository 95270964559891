import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 24px;
    font-weight: bold;
  }

  > p {
    margin-top: 16px;
  }

  form {
    width: 950px;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      background: transparent;
      border-radius: 10px;
      border: 2px solid #091021;
      color: #091021;

      padding: 0 16px;

      width: 500px;
      height: 30px;

      margin-right: 16px;
    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      margin-left: 16px;

      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;

export const Order = styled.div`
  width: 900px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  a {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 50px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    margin-left: 80px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;
