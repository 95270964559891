import TransactionTypes from './TransactionTypes';

const formatTransactionColor = (transactionType) => {
  if ([TransactionTypes.OUTFLOW_PURCHASE].includes(transactionType)) {
    return '#C1322F';
  } if ([TransactionTypes.NEUTRAL_CANCELED_SERVICE_ORDER].includes(transactionType)
        || [TransactionTypes.NEUTRAL_CANCELED_ORDER].includes(transactionType)) {
    return '#091021';
  }
  return '#45B052';
};

export default formatTransactionColor;
