import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;

  h1 {
    margin: 16px;
    font-weight: bold;
  }

  .edit-delete {
    display: flex;
  }

  .edit-delete .edit {
    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    border-radius: 10px;
    height: 26px;
    padding: 0 16px;
    width: 50px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .edit-delete button {
    margin-left: 680px;
    background: transparent;
    border: none;
    color: #091021;
  }
`;

export const NormalOSData = styled.div`
  width: 760px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;
`;

export const ReturnedOSData = styled.div`
  width: 760px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;
`;

export const ExternalOSData = styled.div`
  width: 760px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 24px;

  a {
    border-radius: 10px;
    height: 26px;
    padding: 0 16px;
    width: 80px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 24px;
    text-decoration: none;
  }

  button {
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 140px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 24px;
    text-decoration: none;
  }

  .print1 {
    margin-right: 16px;
    width: 130px;

    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .print2 {
    margin-right: 16px;
    width: 180px;

    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .print3 {
    margin-right: 16px;
    width: 180px;

    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .add-upfront {
    margin-right: 16px;
    width: 190px;


    background: transparent;
    color: #091021;
    border: 2px solid #091021;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .send {
    margin-right: 16px;
    width: 250px;


    background: transparent;
    color: #00950F;
    border: 2px solid #00950F;

    &:hover {
      background: #00950F;
      color: #ffff;
    }
  }

  .cancel {
    background: transparent;
    color: #c53030;
    border: 2px solid #c53030;

    &:hover {
      background: #c53030;
      color: #ffff;
    }
  }
`;
