import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;

  h1 {
    margin: 24px;
    font-weight: bold;
  }

  form {
    width: 895px;
    padding: 0 24px 24px 24px;

    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 2px solid #091021;

    input {
      border: none;
      padding: 2px 8px;
    }

    .return-data {
      margin-top: 24px;
    }

    .return-data strong {
      font-weight: bold;
      font-size: 24px;
    }

    .return-data p {
      margin-top: 16px;
    }

    .repair-data {
      margin-top: 24px;
      border-top: 2px solid #091021;
      padding-top: 24px;
    }

    .repair-data strong {
      font-weight: bold;
      font-size: 24px;
    }

    .repair-data .repair-form {
      display: flex;
    }

    .repair-data .repair {
      display: flex;
    }

    .repair-data .repair strong {
      font-weight: bold;
      font-size: 24px;
    }

    .repair-data .repair p {
      margin-top: 16px;
    }

    .repair-data .details p {
      margin-top: 16px;
    }

    .repair-data .prices {
      margin-left: 48px;
      margin-top: 14px;
    }

    .repair-data .prices .price2 {
      margin-top: 16px;
    }

    .repair-data .prices .price3 {
      margin-top: 16px;
    }

    .repair-data .prices .payment {
      margin-top: 16px;
    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;

      margin-top: 24px;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;

export const StatusArea = styled.div`
  width: 895px;
  margin-top: 16px;
  align-items: center;

  padding-bottom: 24px;

  border-bottom: 2px solid #091021;

  strong {
    font-size: 24px;
    font-weight: bold;
    margin-right: 16px;
  }
`;
