export default function formatStringDate(date) {
  if (date) {
    const day = date.split('/')[0];
    const month = date.split('/')[1];
    const year = date.split('/')[2];

    return `${year}-${(`0${month}`).slice(-2)}-${(`0${day}`).slice(-2)} 00:00`;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }
  return '';
}
