import OrderStatus from './OrderStatus';

const STATUS = {
  [OrderStatus.PENDING]: 'AGUARDANDO PEDIDO',
  [OrderStatus.CLIENT_NOTIFIED]: 'CLIENTE NOTIFICADO',
  [OrderStatus.PIECE_DELIVERED_WITHOUT_PAYMENT]: 'PEDIDO ENTREGUE SEM RECEBIMENTO',
  [OrderStatus.CANCELED]: 'CANCELADO',
  [OrderStatus.COMPLETED]: 'FINALIZADO',
};

export default function getOrderStatusStringFromInt(statusInt) {
  try {
    const statusStr = STATUS[statusInt];
    return statusStr;
  } catch (err) {
    return '';
  }
}
