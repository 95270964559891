import React from 'react';
import GlobalStyle from './styles/global';
import AppRoutes from './routes';
import AppProvider from './hooks';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <AppProvider>
      <Toaster position="top-right" reverseOrder={false} />
      <AppRoutes />
      <GlobalStyle />
    </AppProvider>
  );
}

export default App;
