import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoginHeader = styled.header`
  margin: 0px;
  width: 100%;
  height: 80px;
  background: #091021;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-weight: bold;
    color: #ffff;
  }
`;

export const Content = styled.div`
  width: 320px;
  height: 280px;

  margin-top: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    margin: 80px 0px 60px 0px;
    width: 340px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin-bottom: 16px;
      font-weight: bold;
    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 40px;
      border: 0;
      padding: 0 16px;
      width: 230px;
      color: #fff;
      font-weight: bold;
      margin-top: 16px;
      transition: background-color 0.2s;
      border: 3px solid #091021;
      text-decoration: none;

      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;
