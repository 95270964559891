import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@ERMSystem:token');
    const user = localStorage.getItem('@ERMSystem:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      api.interceptors.response.use(
        response =>
          // Return a successful response back to the calling service
          response,
        error => {
          // Logout user if token expired
          if (error.response.data.message === 'Invalid JWT token') {
            // SIGN OUT
            localStorage.removeItem('@ERMSystem:token');
            localStorage.removeItem('@ERMSystem:user');
            setData({});

            return new Promise((resolve, reject) => {
              reject(error);
            });
          }
          // Return any error which is not due to authentication back to the calling service
          return new Promise((resolve, reject) => {
            reject(error);
          });
        },
      );

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post('sessions', {
      username,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@ERMSystem:token', token);
    localStorage.setItem('@ERMSystem:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    api.interceptors.response.use(
      response =>
        // Return a successful response back to the calling service
        response,
      error => {
        // Logout user if token expired
        if (error.response.data.message === 'Invalid JWT token') {
          // SIGN OUT
          localStorage.removeItem('@ERMSystem:token');
          localStorage.removeItem('@ERMSystem:user');
          setData({});

          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        // Return any error which is not due to authentication back to the calling service
        return new Promise((resolve, reject) => {
          reject(error);
        });
      },
    );

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ERMSystem:token');
    localStorage.removeItem('@ERMSystem:user');

    setData({});
  }, []);

  const updateUser = useCallback(
    user => {
      localStorage.setItem('@ERMSystem:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
