const NormalServiceOrderStatus = Object.freeze({
  BUDGET_PENDING: 100,
  BUDGET_CREATED: 101,
  BUDGET_APPROVED: 102,
  PARTS_PENDING: 103,
  FIXED: 104,
  CLIENT_NOTIFIED: 105,
  PRODUCT_DELIVERED_WITHOUT_PAYMENT: 106,
  CANCELED: 107,
  COMPLETED: 108,
  BUDGET_NOT_APPROVED: 109,
  PRODUCT_NOT_DEFECTIVE: 110,
  BUDGET_NOT_WORTH_IT: 111,
  PARTS_NOT_AVAILABLE: 112,
});

export default NormalServiceOrderStatus;
