import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import getValidationErrors from '../../utils/getValidationErrors';
import formatStringDate from '../../utils/formatStringDate';
import formatUSValue from '../../utils/formatUSValue';

import {
  Container,
  Content,
  FormsArea,
  Clients,
  Client,
  SelectedClient,
} from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import api from '../../services/api';
import toast from 'react-hot-toast';

function ExternalServiceRegistration() {
  const formRefSearch = useRef(null);
  const formRefProduct = useRef(null);
  const history = useNavigate();

  const [clients, setClients] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showClient, setShowClient] = useState(false);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    cpf: '',
    cnpj: '',
    name: '',
    email: '',
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    phone1: '',
    phone2: '',
    phone3: '',
    created_at: '',
    updated_at: '',
  });

  const handleSelectClient = useCallback(client => {
    setSelectedClient({
      id: client.id,
      cpf: client.cpf,
      cnpj: client.cnpj,
      name: client.name,
      email: client.email,
      zip_code: client.zip_code,
      address: client.address,
      number: client.number,
      complement: client.complement,
      neighborhood: client.neighborhood,
      city: client.city,
      state: client.state,
      phone1: client.phone1,
      phone2: client.phone2,
      phone3: client.phone3,
      created_at: client.created_at,
      updated_at: client.updated_at,
    });
    setShowClient(true);
  }, []);

  const handleSearch = useCallback(async data => {
    setShowClient(false);
    setHasSearchedBefore(true);
    try {
      formRefSearch.current.setErrors({});

      const schema = Yup.object().shape({
        search: Yup.string().required('Digite algo para buscar'),
      });

      await schema.validate(data, { abortEarly: false });

      const { search } = data;

      const response = await api.get('/clients', {
        params: {
          filter: search,
        },
      });

      const clientsData = response.data;

      setClients(clientsData);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRefSearch.current.setErrors(errors);

        // return
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRefProduct.current.setErrors({});

        const schema = Yup.object().shape({
          product_name: Yup.string().required('Digite algo para buscar'),
        });

        await schema.validate(data, { abortEarly: false });

        setShowButton(false);

        const response = await api.post('/service-orders/external', {
          client_id: selectedClient.id,
          product_name: data.product_name,
          product_model: data.product_model,
          product_brand: data.product_brand,
          product_serial: data.product_serial,
          product_accessories: data.product_accessories,
          product_details: data.product_details,
          service_details: data.service_details,
          service_type: data.service_type,
          service_date: data.service_date
            ? new Date(formatStringDate(data.service_date))
            : '',
          service_period: data.service_period,
          service_value: formatUSValue(data.service_value),
          service_payment_method: data.service_payment_method,
        });

        const newServiceOrder = response.data;

        toast.success('Atendimento Externo Cadastrado com sucesso!');

        history(`/os/${newServiceOrder.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefProduct.current.setErrors(errors);
        }

        toast.error(
          'Ocorreu um erro ao criar o Atendimento Externo. Cheque os dados.',
        );

        setShowButton(true);
      }
    },
    [history, selectedClient.id],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Abrir Serviço Externo</h1>

        <FormsArea>
          <Form ref={formRefSearch} onSubmit={handleSearch}>
            <div className="search">
              <div className="client">
                <p>Nome do Cliente, CPF ou CNPJ</p>
                <Input
                  name="search"
                  containerStyle={{
                    width: '280px',
                  }}
                  type="text"
                />
              </div>
              <button type="submit">Buscar</button>
            </div>
          </Form>

          {!showClient && clients.length > 0 ? (
            <Clients>
              {clients.map(client => (
                <Client key={client.id}>
                  <div className="client">
                    <strong>{client.name}</strong>
                    {client.cpf && <p>CPF: {client.cpf || '-'}</p>}
                    {client.cnpj && <p>CNPJ: {client.cnpj || '-'}</p>}
                  </div>
                  <button
                    className="select-button"
                    onClick={() => handleSelectClient(client)}
                    type="button"
                  >
                    Selecionar
                  </button>
                </Client>
              ))}
            </Clients>
          ) : (
            !showClient && hasSearchedBefore && <p>Nenhum cliente encontrado</p>
          )}

          {showClient && (
            <SelectedClient>
              <strong>{selectedClient.name}</strong>
              <p>
                <b>CPF:</b> {selectedClient.cpf || '-'}
              </p>
              <p>
                <b>CNPJ:</b> {selectedClient.cnpj || '-'}
              </p>
              <br />
              <p>
                <b>Contato:</b>
              </p>
              <p>E-mail: {selectedClient.email || '-'}</p>
              <p>
                Telefones: {selectedClient.phone1 || '-'} /{' '}
                {selectedClient.phone2 || '-'} / {selectedClient.phone3 || '-'}
              </p>
              <br />

              <p>
                <b>Endereço:</b>
              </p>
              <p> CEP: {selectedClient.zip_code || '-'} </p>
              <p> Rua: {selectedClient.address || '-'}</p>
              <p> Número: {selectedClient.number || '-'}</p>
              <p> Bairro: {selectedClient.neighborhood || '-'}</p>
              <p> Complemento: {selectedClient.complement || '-'}</p>
            </SelectedClient>
          )}

          <Form ref={formRefProduct} onSubmit={handleSubmit}>
            <section className="product-data">
              <strong>Informações do Produto</strong>

              <div className="name-model-brand">
                <div className="name">
                  <p>Nome</p>
                  <Input
                    type="text"
                    name="product_name"
                    containerStyle={{
                      width: '350px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="model">
                  <p>Modelo</p>
                  <Input
                    type="text"
                    name="product_model"
                    containerStyle={{
                      width: '300px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="brand">
                  <p>Marca</p>
                  <Input
                    type="text"
                    name="product_brand"
                    inputStyle={{
                      width: '134px',
                    }}
                    containerStyle={{
                      width: '150px',
                      marginRight: '16px',
                    }}
                  />
                </div>
              </div>

              <div className="number-accessories">
                <div className="number">
                  <p>Nº de Série</p>
                  <Input
                    type="text"
                    name="product_serial"
                    containerStyle={{
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="accessories">
                  <p>Acessórios</p>
                  <Input
                    type="text"
                    name="product_accessories"
                    containerStyle={{
                      width: '422px',
                    }}
                  />
                </div>
              </div>

              <div className="details">
                <p>Defeito Reclamado</p>
                <Input
                  type="text"
                  name="product_details"
                  containerStyle={{
                    width: '690px',
                    marginBottom: '24px',
                  }}
                />
              </div>

              <strong>Informações do Atendimento Externo</strong>

              <div className="type-date-period">
                <div className="type">
                  <p>Tipo</p>
                  <Input
                    type="text"
                    name="service_type"
                    containerStyle={{
                      width: '310px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="date">
                  <p>Data</p>
                  <Input
                    type="text"
                    name="service_date"
                    inputStyle={{
                      width: '134px',
                    }}
                    containerStyle={{
                      width: '150px',
                      marginRight: '16px',
                    }}
                  />
                </div>
                <div className="period">
                  <p>Período</p>
                  <Input
                    type="text"
                    name="service_period"
                    inputStyle={{
                      width: '134px',
                    }}
                    containerStyle={{
                      width: '150px',
                      marginRight: '16px',
                    }}
                  />
                </div>
              </div>

              <div className="value_payment_method">
                <div className="number">
                  <p>Valor</p>
                  <Input
                    type="text"
                    name="service_value"
                    inputStyle={{
                      width: '134px',
                    }}
                    containerStyle={{
                      width: '150px',
                      marginRight: '16px',
                    }}
                  />
                </div>

                <div className="payment_method">
                  <p>Método de Pagamento</p>
                  <Input
                    type="text"
                    name="service_payment_method"
                    inputStyle={{
                      width: '164px',
                    }}
                    containerStyle={{
                      width: '180px',
                      marginRight: '16px',
                    }}
                  />
                </div>
              </div>

              <div className="details">
                <p>Detalhes</p>
                <Input
                  type="text"
                  name="service_details"
                  containerStyle={{
                    width: '690px',
                    marginBottom: '24px',
                  }}
                />
              </div>

              {showButton && <button type="submit">Salvar</button>}
            </section>
          </Form>
        </FormsArea>
      </Content>
    </Container>
  );
}

export default ExternalServiceRegistration;
