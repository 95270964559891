import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const InputOverrideStyle = {
  background: 'transparent',
  borderRadius: '10px',
  border: '2px solid #091021',
  color: '#091021',

  padding: '0 16px',

  width: '500px',
  height: '30px',

  marginRight: '16px',
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 24px;
    font-weight: bold;
  }

  > p {
    margin-top: 16px;
  }

  form {
    width: 950px;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    select {
      margin-top: 8px;

    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      margin-left: 16px;
      margin-top: 8px;

      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;

export const ClientResult = styled.div`
  width: 940px;
  margin-top: 24px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;

  h2 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ClientContainer = styled.div``;

export const ClientData = styled.div`

  strong {
    font-size: 26px;
    font-weight: bold;
  }

  a {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 200px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 24px;
    text-decoration: none;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }

  .name-button {
    display: grid;
    grid-template-columns: 850px 100px;
    list-style: none;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .view-hidde-button {
    background: transparent;
    border: none;
    color: #091021;
  }
`;

export const OS = styled.div`
  width: 900px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  button {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 100px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    margin-left: 70px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;

export const Order = styled.div`
  width: 900px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  button {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 100px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    margin-left: 70px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;

export const OSClient = styled.div`
  width: 900px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  button {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 100px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    margin-left: 70px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;
