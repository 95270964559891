import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import getValidationErrors from '../../utils/getValidationErrors';
import getNormalSOStatusStringFromInt from '../../utils/getNormalSOStatusStringFromInt';
import getOrderStatusStringFromInt from '../../utils/getOrderStatusStringFromInt';
import getReturnedSOStatusStringFromInt from '../../utils/getReturnedSOStatusStringFromInt';
import getExternalSOStatusStringFromInt from '../../utils/getExternalSOStatusStringFromInt';
import api from '../../services/api';
import Input from '../../components/Input';
import Select from '../../components/Select';
import MenuBar from '../../components/MenuBar';

import {
  Container,
  Content,
  ClientResult,
  ClientContainer,
  ClientData,
  OS,
  Order,
  OSClient,
  InputOverrideStyle,
} from './styles';
import toast from 'react-hot-toast';

function Consulta() {
  const formRef = useRef(null);

  // Stores the search text once it is submitted (used for new queries due to pagination)
  const [search, setSearch] = useState('');
  // Determine if the user is searching for clients or service orders or orders
  const [searchClient, setSearchClient] = useState(false);
  const [searchServiceOrder, setSearchServiceOrder] = useState(false);
  const [searchOrder, setSearchOrder] = useState(false);
  // Store API response
  const [clients, setClients] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [orders, setOrders] = useState([]);

  const handleViewInfo = useCallback(id => {
    const clientInfo = document.getElementById(`clientInfo${id}`);
    const clientOSInfo = document.getElementById(`clientOSInfo${id}`);
    const clientOrderInfo = document.getElementById(`clientOrderInfo${id}`);

    const viewButton = document.getElementById(`show${id}`);
    const hiddeButton = document.getElementById(`hidde${id}`);

    if (!clientInfo || !clientOSInfo || !viewButton || !hiddeButton) {
      return;
    }

    clientInfo.style.display = '';
    clientOSInfo.style.display = '';
    clientOrderInfo.style.display = '';
    viewButton.style.display = 'none';
    hiddeButton.style.display = '';
  }, []);

  const handleHiddeInfo = useCallback(id => {
    const clientInfo = document.getElementById(`clientInfo${id}`);
    const clientOrderInfo = document.getElementById(`clientOrderInfo${id}`);
    const clientOSInfo = document.getElementById(`clientOSInfo${id}`);

    const viewButton = document.getElementById(`show${id}`);
    const hiddeButton = document.getElementById(`hidde${id}`);

    if (!clientInfo || !clientOSInfo || !viewButton || !hiddeButton) {
      return;
    }

    clientInfo.style.display = 'none';
    clientOSInfo.style.display = 'none';
    clientOrderInfo.style.display = 'none';
    viewButton.style.display = '';
    hiddeButton.style.display = 'none';
  }, []);

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        search_text: Yup.string().required('Digite algo para buscar'),
        search_option: Yup.string().required(),
      });

      await schema.validate(data, { abortEarly: false });

      const { search_option, search_text } = data;

      setSearch(search_text);

      if (search_option === 'clients') {
        setSearchClient(true);
        setSearchServiceOrder(false);
        setSearchOrder(false);

        const response = await api.get('/clients', {
          params: {
            filter: search_text,
          },
        });
        const clientsData = response.data;

        setClients(
          clientsData.map(client => ({
            ...client,
            service_orders: [
              ...client.normal_service_orders,
              ...client.returned_service_orders,
            ],
          })),
        );
      } else if (search_option === 'service-orders') {
        setSearchClient(false);
        setSearchServiceOrder(true);
        setSearchOrder(false);

        const response = await api.get('/service-orders', {
          params: {
            number: search_text,
          },
        });
        const serviceOrdersData = response.data;
        setServiceOrders(serviceOrdersData);
      } else if (search_option === 'orders') {
        setSearchClient(false);
        setSearchServiceOrder(false);
        setSearchOrder(true);

        const response = await api.get('/orders', {
          params: {
            number: search_text,
          },
        });
        const ordersData = response.data;

        setOrders(ordersData);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current.setErrors(errors);

        return;
      }

      toast.error('Ocorreu um erro ao fazer a busca. Verifique a conexão.');
    }
  }, []);

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Consulta</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="search_text"
            type="text"
            containerStyle={InputOverrideStyle}
          />
          <Select name="search_option">
            <option value="clients">Cliente</option>
            <option value="service-orders">O.S.</option>
            <option value="orders">Pedido</option>
          </Select>
          <button type="submit">Buscar</button>
        </Form>

        {searchClient &&
          (clients.length > 0 ? (
            clients.map(client => (
              <ClientResult key={client.id}>
                <ClientContainer>
                  <ClientData>
                    <div className="name-button">
                      <strong>{client.name}</strong>
                      <button
                        id={`show${client.id}`}
                        className="view-hidde-button"
                        type="button"
                        onClick={() => handleViewInfo(client.id)}
                      >
                        <AiOutlineDown />
                      </button>
                      <button
                        id={`hidde${client.id}`}
                        className="view-hidde-button"
                        type="button"
                        onClick={() => handleHiddeInfo(client.id)}
                        style={{ display: 'none' }}
                      >
                        <AiOutlineUp />
                      </button>
                    </div>
                    <p>
                      <b>CPF:</b> {client.cpf || '-'}
                    </p>
                    <p>
                      <b>CNPJ:</b> {client.cnpj || '-'}
                    </p>

                    <div
                      id={`clientInfo${client.id}`}
                      style={{ display: 'none' }}
                    >
                      <br />
                      <p>
                        <b>Contato:</b>
                      </p>
                      <p>E-mail: {client.email || '-'}</p>
                      <p>
                        Telefones: {client.phone1 || '-'} /{' '}
                        {client.phone2 || '-'} / {client.phone3 || '-'}
                      </p>
                      <br />

                      <p>
                        <b>Endereço:</b>
                      </p>
                      <p> CEP: {client.zip_code || '-'} </p>
                      <p> Rua: {client.address || '-'}</p>
                      <p> Número: {client.number || '-'}</p>
                      <p> Bairro: {client.neighborhood || '-'}</p>
                      <p> Complemento: {client.complement || '-'}</p>
                      <br />
                      <Link to={`/editar-cliente/${client.id}`}>
                        Editar dados do cliente
                      </Link>
                    </div>
                  </ClientData>
                  <div
                    id={`clientOSInfo${client.id}`}
                    style={{ display: 'none' }}
                  >
                    <h2>Ordens de Serviço</h2>
                    {client.service_orders &&
                    client.service_orders.length > 0 ? (
                      client.service_orders.map(service_order => (
                        <OSClient key={service_order.id}>
                          <strong>{service_order.number}</strong>
                          <p>{service_order.product_name}</p>
                          <p>
                            Status:{' '}
                            {getNormalSOStatusStringFromInt(
                              service_order.status,
                            ) ||
                              getReturnedSOStatusStringFromInt(
                                service_order.status,
                              )}
                          </p>
                          <Link to={`/os/${service_order.id}`}>
                            <button type="button">Abrir</button>
                          </Link>
                        </OSClient>
                      ))
                    ) : (
                      <p>Ainda não há Ordens de Serviço para este cliente</p>
                    )}
                  </div>
                  <div
                    id={`clientOrderInfo${client.id}`}
                    style={{ display: 'none', marginTop: '16px' }}
                  >
                    <h2>Pedidos</h2>
                    {client.orders && client.orders.length > 0 ? (
                      client.orders.map(order => (
                        <OSClient key={order.id}>
                          <strong>{order.number}</strong>
                          <p>{order.product_name}</p>
                          <p>
                            Status:{' '}
                            {getNormalSOStatusStringFromInt(order.status) ||
                              getReturnedSOStatusStringFromInt(order.status)}
                          </p>
                          <Link to={`/pedido/${order.id}`}>
                            <button type="button">Abrir</button>
                          </Link>
                        </OSClient>
                      ))
                    ) : (
                      <p>Ainda não há pedidos para este cliente</p>
                    )}
                  </div>
                </ClientContainer>
              </ClientResult>
            ))
          ) : (
            <p>Nenhum cliente encontrado com esse nome, CPF ou CNPJ</p>
          ))}
        {searchServiceOrder &&
          (serviceOrders.length > 0 ? (
            serviceOrders.map(serviceOrder => (
              <OS key={serviceOrder.id}>
                <strong>{serviceOrder.number}</strong>
                <p>{serviceOrder.client.name}</p>
                <p>{serviceOrder.product_name}</p>
                <p>
                  Status:{' '}
                  {getNormalSOStatusStringFromInt(serviceOrder.status) ||
                    getReturnedSOStatusStringFromInt(serviceOrder.status) ||
                    getExternalSOStatusStringFromInt(serviceOrder.status)}
                </p>
                <Link to={`/os/${serviceOrder.id}`}>
                  <button type="button">Abrir</button>
                </Link>
              </OS>
            ))
          ) : (
            <p>Nenhuma Ordem de Serviço encontrada com este número</p>
          ))}

        {searchOrder &&
          (orders.length > 0 ? (
            orders.map(order => (
              <Order key={order.id}>
                <strong>{order.number}</strong>
                <p>{order.client.name}</p>
                <p>{order.piece_name}</p>
                <p>Status: {getOrderStatusStringFromInt(order.status)}</p>
                <Link to={`/pedido/${order.id}`}>
                  <button type="button">Abrir</button>
                </Link>
              </Order>
            ))
          ) : (
            <p>Nenhum Pedido encontrado com este número</p>
          ))}
      </Content>
    </Container>
  );
}

export default Consulta;
