import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { FiEdit } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';
import formatValue from '../../utils/formatValue';
import formatUSValue from '../../utils/formatUSValue';
import formatBRValue from '../../utils/formatBRValue';
import getTransactionTypeStringFromInt from '../../utils/getTransactionTypeStringFromInt';
import formatSignedTransactionValue from '../../utils/formatSignedTransactionValue';
import isEditableTransaction from '../../utils/isEditableTransaction';
import formatTransactionColor from '../../utils/formatTransactionColor';
import isInflowTransaction from '../../utils/isInflowTransaction';
import isOutflowTransaction from '../../utils/isOutflowTransaction';
import isManager from '../../utils/isManager';

import * as printStyle from './printStyle.css';
import headerLogo from '../../assets/headerLogo.png';

import Input from '../../components/Input';
import Select from '../../components/Select';
import MenuBar from '../../components/MenuBar';

import {
  Container,
  Content,
  Transactions,
  Transaction,
  Buttons,
} from './styles';
import toast from 'react-hot-toast';

// Estilo do EDIT/DELETE modal
const editTransactionStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',
    height: '490px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 16px',
    width: '230px',
    height: '30px',

    marginBottom: '8px',
  },
  saveButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginRight: '16px',
    padding: '0 16px',
  },
  deleteButton: {
    background: 'transparent',
    color: '#c53030',
    border: '2px solid #c53030',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '90px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginRight: '16px',
    padding: '0 16px',
  },
}));

// Estilo do CREATE modal
const createTransactionStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '260px',
    height: '360px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '222px',
    height: '30px',
    marginBottom: '8px',
  },
  createButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '75px',
    padding: '0 16px',
  },
}));

// Estilo do ACCESS modal
const accessStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '260px',
    height: '220px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '222px',
    height: '30px',
    marginBottom: '8px',
  },
  accessButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '52px',
    padding: '0 16px',
  },
}));

function TodayCashRegister() {
  const history = useNavigate();
  // Store API response
  const [transactions, setTransactions] = useState([]);
  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
    pageStyle: '',
    onAfterPrint: () => handleHidePrint1(),
  });

  const handleShowPrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = '';
  }, []);

  const handleHidePrint1 = useCallback(() => {
    const print1 = document.getElementById('print1');

    print1.style.display = 'none';
  }, []);

  // Modal de ACESSO ////////////////////////////////////////////////////////////////////
  const [isAuthenticatedManager, setIsAuthenticatedManager] = useState(false);
  const [managerToken, setManagerToken] = useState('');
  const accessFormRef = useRef(null);

  useEffect(() => {
    handleOpenAccessModal(true);
  }, []);

  const accessClasses = accessStyles();
  const [openAccessModal, setOpenAccessModal] = React.useState(false);

  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (managerToken) {
      api
        .get('/transactions/day', {
          params: {
            date: currentDate,
          },
          headers: {
            authorization: `Bearer ${managerToken}`,
          },
        })
        .then(response => {
          const transactionsData = response.data;

          setTransactions(
            transactionsData.map(transaction => ({
              ...transaction,
              formattedType: getTransactionTypeStringFromInt(transaction.type),
              formattedDate: formatDate(transaction.date),
              formattedValue: formatSignedTransactionValue(
                transaction.type,
                transaction.value,
              ),
              formattedColor: formatTransactionColor(transaction.type),
            })),
          );

          const print1 = document.getElementById('print1');

          print1.style.display = 'none';
        });
    }
  }, [currentDate, managerToken]);

  const dayBalance = useMemo(() => {
    console.log(transactions);
    const balance = transactions.reduce((accumulator, currentTransaction) => {
      if (isInflowTransaction(currentTransaction.type)) {
        return accumulator + Number(currentTransaction.value);
      }
      if (isOutflowTransaction(currentTransaction.type)) {
        return accumulator - Number(currentTransaction.value);
      }
      return accumulator;
    }, 0.0);
    return balance;
  }, [transactions]);

  const formattedDayBalance = useMemo(
    () => formatValue(dayBalance),
    [dayBalance],
  );

  const handleAccess = useCallback(async data => {
    try {
      accessFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        username: Yup.string().required('Usuário obrigatório'),
        password: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await api.post('sessions', data);
      const { user, token } = response.data;

      if (isManager(user.authority_level)) {
        setIsAuthenticatedManager(true);
        setManagerToken(token);
        setOpenAccessModal(false);
      }

      return;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        accessFormRef.current.setErrors(errors);

        return;
      }

      toast.error(
        'Ocorreu um erro ao fazer login. Digite credenciais válidas para um(a) gerente',
      );
    }
  }, []);

  const handleOpenAccessModal = () => {
    setOpenAccessModal(true);
  };

  const handleCloseAccessModal = () => {
    setOpenAccessModal(false);
  };

  // Modal que CRIA transacoes ///////////////////////////////////////////////////////////
  const createTransactionClasses = createTransactionStyles();
  const [openCreateTransactionModal, setOpenCreateTransactionModal] =
    React.useState(false);
  const createTransactionFormRef = useRef(null);

  const handleCreateTransaction = useCallback(
    async data => {
      try {
        createTransactionFormRef.current.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required('Tipo obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          value: Yup.string().required('Valor obrigatório'),
          payment_method: Yup.string().required('Valor obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const { type, value, description, payment_method } = data;

        const transactionResponse = await api.post('/transactions', {
          type,
          value: formatUSValue(value),
          description,
          date: new Date(),
          payment_method,
        });

        const newTransaction = {
          ...transactionResponse.data,
          formattedType: getTransactionTypeStringFromInt(
            transactionResponse.data.type,
          ),
          formattedDate: formatDate(transactionResponse.data.date),
          formattedValue: formatSignedTransactionValue(
            transactionResponse.data.type,
            transactionResponse.data.value,
          ),
          formattedColor: formatTransactionColor(transactionResponse.data.type),
        };
        const newTransactions = [...transactions];
        newTransactions.push(newTransaction);
        setTransactions(newTransactions);

        setOpenCreateTransactionModal(false);

        //history.go(0);
        history('/caixa-hoje', { replace: true });

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          createTransactionFormRef.current.setErrors(errors);

          return;
        }

        toast.error('Ocoreu um erro ao criar a transação. Cheque os valores.');
      }
    },
    [transactions, history],
  );

  const handleOpenCreateTransactionModal = () => {
    setOpenCreateTransactionModal(true);
  };

  const handleCloseCreateTransactionModal = () => {
    setOpenCreateTransactionModal(false);
  };

  // Modal que EDITA/DELETA transacoes ///////////////////////////////////////////////////////////
  const editTransactionClasses = editTransactionStyles();
  const [openEditTransactionModal, setOpenEditTransactionModal] =
    React.useState(false);
  const [editTransactionModalData, setEditTransactionModalData] = useState({
    type: '',
    description: '',
    payment_method: '',
    value: '',
  });
  const editTransactionFormRef = useRef(null);

  const handleEditTransaction = useCallback(async () => {
    if (editTransactionModalData) {
      try {
        editTransactionFormRef.current.setErrors({});
        const data = editTransactionFormRef.current.getData();

        const schema = Yup.object().shape({
          type: Yup.string().required('Tipo obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
          value: Yup.string().required('Valor obrigatório'),
          payment_method: Yup.string().required('Valor obrigatório'),
          username: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { username, password, type, value, description, payment_method } =
          data;

        const authenticationResponse = await api.post('sessions', {
          username,
          password,
        });
        const { user, token } = authenticationResponse.data;

        if (!isManager(user.authority_level)) {
          toast.error('Digite uma credencial de gerente válida');
          return;
        }

        const transactionResponse = await api.put(
          `/transactions/${editTransactionModalData.id}`,
          {
            type,
            value: formatUSValue(value),
            description,
            payment_method,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );

        const updatedTransaction = {
          ...transactionResponse.data,
          formattedType: getTransactionTypeStringFromInt(
            transactionResponse.data.type,
          ),
          formattedDate: formatDate(transactionResponse.data.date),
          formattedValue: formatSignedTransactionValue(
            transactionResponse.data.type,
            transactionResponse.data.value,
          ),
          formattedColor: formatTransactionColor(transactionResponse.data.type),
        };

        const findTransactionIndex = transactions.findIndex(
          transaction => transaction.id === updatedTransaction.id,
        );
        const newTransactions = [...transactions];
        newTransactions[findTransactionIndex] = updatedTransaction;
        setTransactions(newTransactions);

        setOpenEditTransactionModal(false);
        history('/caixa-hoje', { replace: true });

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          editTransactionFormRef.current.setErrors(errors);

          return;
        }

        toast.error(
          'Ocorreu um erro ao editar a transação. Cheque os valores.',
        );
      }
    }
  }, [editTransactionModalData, transactions, history]);

  const handleDeleteTransaction = useCallback(async () => {
    if (editTransactionModalData) {
      try {
        editTransactionFormRef.current.setErrors({});
        const data = editTransactionFormRef.current.getData();

        const schema = Yup.object().shape({
          username: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { username, password } = data;

        const authenticationResponse = await api.post('sessions', {
          username,
          password,
        });
        const { user, token } = authenticationResponse.data;

        if (!isManager(user.authority_level)) {
          toast.error('Digite uma credencial de gerente válida');
          return;
        }

        await api.delete(`/transactions/${editTransactionModalData.id}`, {
          data: {
            confirm_username: username,
            confirm_password: password,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        const findTransactionIndex = transactions.findIndex(
          transaction => transaction.id === editTransactionModalData.id,
        );
        const newTransactions = [...transactions];
        newTransactions.splice(findTransactionIndex, 1);
        setTransactions(newTransactions);

        setOpenEditTransactionModal(false);

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          editTransactionFormRef.current.setErrors(errors);

          return;
        }

        toast.error(
          'Ocorreu um erro ao excluir a transação. Cheque os valores.',
        );
      }
    }
  }, [editTransactionModalData, transactions]);

  const handleOpenEditTransactionModal = transaction => {
    setEditTransactionModalData(transaction);
    setOpenEditTransactionModal(true);
  };

  const handleCloseEditTransactionModal = () => {
    setOpenEditTransactionModal(false);
  };

  return (
    <Container>
      <MenuBar />

      {!isAuthenticatedManager && <h1>Acesso não autorizado</h1>}

      {/* // Modal que de ACESSO ao caixa /////////////////////////////// */}
      <div>
        <Modal
          className={accessClasses.modal}
          open={openAccessModal}
          onClose={handleCloseAccessModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAccessModal}>
            <div className={accessClasses.paper}>
              <h2>
                <b>Acessar Caixa</b>
              </h2>
              <br />

              <div>
                <strong>
                  <b>Confirme login do gerente:</b>
                </strong>
                <Form ref={accessFormRef} onSubmit={handleAccess}>
                  <div className={accessClasses.inputs}>
                    <Input
                      className={accessClasses.input}
                      inputStyle={{ width: '200px' }}
                      name="username"
                      type="text"
                      placeholder="Usuario"
                    />
                    <Input
                      className={accessClasses.input}
                      inputStyle={{ width: '200px' }}
                      name="password"
                      type="password"
                      placeholder="Senha"
                    />
                  </div>

                  <button
                    onClick={handleAccess}
                    type="submit"
                    className={accessClasses.accessButton}
                  >
                    Acessar
                  </button>

                  <button
                    onClick={handleCloseAccessModal}
                    type="button"
                    className={accessClasses.cancelButton}
                  >
                    Cancelar
                  </button>
                </Form>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>

      {isAuthenticatedManager && (
        <>
          {/* // Modal que CRIA transacoes /////////////////////////////// */}
          <div>
            <Modal
              className={createTransactionClasses.modal}
              open={openCreateTransactionModal}
              onClose={handleCloseCreateTransactionModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openCreateTransactionModal}>
                <div className={createTransactionClasses.paper}>
                  <h2>
                    <b>Criar Lançamento</b>
                  </h2>
                  <br />

                  <div>
                    <Form
                      ref={createTransactionFormRef}
                      onSubmit={handleCreateTransaction}
                    >
                      <div>
                        <p>Tipo: </p>
                        <Select
                          name="type"
                          className={createTransactionClasses.selector}
                        >
                          <option value="103">Entrada</option>
                          <option value="201">Saida</option>
                        </Select>
                        <p>Descrição: </p>
                        <Input
                          name="description"
                          className={createTransactionClasses.input}
                          containerStyle={{ marginBottom: '8px' }}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                        <p>Forma de Pagamento: </p>
                        <Input
                          name="payment_method"
                          className={createTransactionClasses.input}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                        <p style={{ marginTop: '8px' }}>Valor: </p>
                        <Input
                          name="value"
                          className={createTransactionClasses.input}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                      </div>

                      <button
                        type="submit"
                        className={createTransactionClasses.createButton}
                      >
                        Criar
                      </button>
                      <button
                        onClick={handleCloseCreateTransactionModal}
                        type="button"
                        className={createTransactionClasses.cancelButton}
                      >
                        Cancelar
                      </button>
                    </Form>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>

          {/* // Modal que EDITA/DELETA transacoes /////////////////////////////// */}
          <div>
            <Modal
              className={editTransactionClasses.modal}
              open={openEditTransactionModal}
              onClose={handleCloseEditTransactionModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openEditTransactionModal}>
                <div className={editTransactionClasses.paper}>
                  <h2>
                    <b>Editar Lançamento</b>
                  </h2>
                  <br />

                  <div>
                    <Form
                      ref={editTransactionFormRef}
                      initialData={{
                        type: editTransactionModalData.type,
                        description: editTransactionModalData.description,
                        payment_method: editTransactionModalData.payment_method,
                        value: formatBRValue(editTransactionModalData.value),
                      }}
                    >
                      <div>
                        <p>Tipo: </p>
                        <Select
                          name="type"
                          className={editTransactionClasses.selector}
                        >
                          <option value="103">Entrada</option>
                          <option value="201">Saida</option>
                        </Select>
                        <p>Descrição: </p>
                        <Input
                          name="description"
                          className={createTransactionClasses.input}
                          containerStyle={{ marginBottom: '8px' }}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                        <p>Forma de Pagamento: </p>
                        <Input
                          name="payment_method"
                          className={createTransactionClasses.input}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                        <p style={{ marginTop: '8px' }}>Valor: </p>
                        <Input
                          name="value"
                          className={createTransactionClasses.input}
                          inputStyle={{ width: '200px' }}
                          type="text"
                        />
                      </div>
                      <br />

                      <strong>
                        <b>Confirme login do gerente:</b>
                      </strong>
                      <div className={editTransactionClasses.inputs}>
                        <Input
                          className={accessClasses.input}
                          inputStyle={{ width: '200px' }}
                          name="username"
                          type="text"
                          placeholder="Usuario"
                        />
                        <Input
                          className={accessClasses.input}
                          inputStyle={{ width: '200px' }}
                          name="password"
                          type="password"
                          placeholder="Senha"
                        />
                      </div>

                      <button
                        onClick={handleEditTransaction}
                        type="button"
                        className={editTransactionClasses.saveButton}
                      >
                        Salvar
                      </button>

                      <button
                        onClick={handleCloseEditTransactionModal}
                        type="button"
                        className={editTransactionClasses.cancelButton}
                      >
                        Cancelar
                      </button>

                      <button
                        onClick={() =>
                          handleDeleteTransaction(editTransactionModalData.id)
                        }
                        type="button"
                        className={editTransactionClasses.deleteButton}
                      >
                        Excluir
                      </button>
                    </Form>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>

          <Content>
            <h1>Caixa - {formatDate(currentDate)}</h1>
            <p>Total: {formattedDayBalance}</p>
            <Transactions>
              {transactions.length > 0 ? (
                transactions.map(transaction => (
                  <Transaction key={transaction.id}>
                    <div className="transaction-data">
                      <p>{transaction.formattedDate}</p>
                      <p>{transaction.formattedType}</p>
                      <p>{transaction.description}</p>
                      <p style={{ color: transaction.formattedColor }}>
                        {transaction.formattedValue}
                      </p>
                      <p>
                        {transaction.payment_method
                          ? transaction.payment_method
                          : '-'}
                      </p>
                      <p>
                        {transaction.service_order
                          ? transaction.service_order.number
                          : '-'}
                      </p>
                      <p>
                        {transaction.service_order
                          ? transaction.service_order.product_name
                          : '-'}
                      </p>
                      <p>
                        {transaction.service_order
                          ? transaction.service_order.product_brand
                          : '-'}
                      </p>
                      <p>
                        {transaction.service_order
                          ? transaction.service_order.client.name
                          : '-'}
                      </p>
                    </div>
                    <div className="icons">
                      {isEditableTransaction(transaction.type) ? (
                        <button
                          type="button"
                          onClick={() =>
                            handleOpenEditTransactionModal(transaction)
                          }
                        >
                          <FiEdit />
                        </button>
                      ) : null}
                    </div>
                  </Transaction>
                ))
              ) : (
                <p>Não foi encontrada nenhuma transação para esta data.</p>
              )}
            </Transactions>

            <div ref={componentRef1} className="print1" id="print1">
              <div className="main">
                <link type="stylesheet" url={printStyle} />
                <div className="header">
                  <img src={headerLogo} alt="Logo" />
                  <div className="today-header-right">
                    <h1>Caixa - {formatDate(currentDate)}</h1>
                    <strong>Total: {formattedDayBalance}</strong>
                  </div>
                </div>
                <div className="transactions">
                  {transactions.length > 0 ? (
                    transactions.map(transaction => (
                      <Transaction key={transaction.id}>
                        <div className="transaction-data">
                          <p>{transaction.formattedDate}</p>
                          <p>{transaction.formattedType}</p>
                          <p>{transaction.description}</p>
                          <p style={{ color: transaction.formattedColor }}>
                            {transaction.formattedValue}
                          </p>
                          <p>
                            {transaction.payment_method
                              ? transaction.payment_method
                              : '-'}
                          </p>
                          <p>
                            {transaction.service_order
                              ? transaction.service_order.number
                              : '-'}
                          </p>
                          <p>
                            {transaction.service_order
                              ? transaction.service_order.product_name
                              : '-'}
                          </p>
                          <p>
                            {transaction.service_order
                              ? transaction.service_order.product_brand
                              : '-'}
                          </p>
                          <p>
                            {transaction.service_order
                              ? transaction.service_order.client.name
                              : '-'}
                          </p>
                        </div>
                      </Transaction>
                    ))
                  ) : (
                    <p>Não foi encontrada nenhuma transação para esta data.</p>
                  )}
                </div>
              </div>
            </div>

            <Buttons>
              <button
                className="print"
                type="button"
                onClick={() => {
                  handleShowPrint1();
                  handlePrint1();
                }}
              >
                Imprimir página
              </button>
              <button
                type="button"
                onClick={handleOpenCreateTransactionModal}
                className="create"
              >
                Criar Lançamento
              </button>
            </Buttons>
          </Content>
        </>
      )}
    </Container>
  );
}

export default TodayCashRegister;
