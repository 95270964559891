import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Content, StatusArea } from './styles';
import formatStringDate from '../../utils/formatStringDate';

import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';
import ReturnedServiceOrderStatus from '../../utils/ReturnedServiceOrderStatus';
import formatUSValue from '../../utils/formatUSValue';
import formatBRValue from '../../utils/formatBRValue';

import api from '../../services/api';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import toast from 'react-hot-toast';

function UpdateReturnedOS() {
  const formRef = useRef(null);
  const { id } = useParams();
  const history = useNavigate();

  const [returnedServiceOrder, setReturnedServiceOrder] = useState({
    client: '',
    created_at: '',
    id: '',
    number: '',
    original_service_order: {
      client: {
        address: '',
        city: '',
        complement: '',
        cpf: '',
        created_at: '',
        email: '',
        id: '',
        name: '',
        neighborhood: '',
        number: '',
        phone1: '',
        phone2: '',
        phone3: '',
        state: '',
        updated_at: '',
        zip_code: '',
      },
      created_at: '',
      id: '',
      number: '',
      return_motive: '',
      status: -1,
      type: -1,
      updated_at: '',
    },
    return_motive: '',
    status: -1,
    transactions: [],
    type: -1,
    updated_at: '',
  });

  useEffect(() => {
    api.get(`/service-orders/returned/${id}`).then(response => {
      const returnedServiceOrderData = response.data;
      setReturnedServiceOrder(returnedServiceOrderData);
    });
    formRef.current.setFieldValue('status', returnedServiceOrder.status);
  }, [id, returnedServiceOrder.status]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          return_motive: Yup.string().required('Digite algo para editar'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put(`/service-orders/returned/${id}`, {
          status: data.status,
          return_motive: data.return_motive,
          fix_technician_name: data.fix_technician_name,
          fix_date: data.fix_date
            ? new Date(formatStringDate(data.fix_date))
            : '',
          fix_description: data.fix_description,
          fix_value_pieces: formatUSValue(data.fix_value_pieces),
          fix_value_labor: formatUSValue(data.fix_value_labor),
          fix_value_final: formatUSValue(data.fix_value_final),
          fix_payment_method: data.fix_payment_method,
        });

        const updatedServiceOrder = response.data;

        toast.success('O.S. de Retorno editada com sucesso!');

        history(`/os/${updatedServiceOrder.id}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        toast.error(
          'Ocorreu um erro ao editar a O.S. Cheque suas credenciais.',
        );
      }
    },
    [history, id],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Editar O.S.: {returnedServiceOrder.number}</h1>

        <Form
          ref={formRef}
          initialData={{
            status: returnedServiceOrder.status,
            return_motive: returnedServiceOrder.return_motive,
            fix_technician_name: returnedServiceOrder.fix_technician_name,
            fix_date: returnedServiceOrder.fix_date
              ? formatDate(new Date(returnedServiceOrder.fix_date))
              : '',
            fix_description: returnedServiceOrder.fix_description,
            fix_value_pieces: formatBRValue(
              returnedServiceOrder.fix_value_pieces,
            ),
            fix_value_labor: formatBRValue(
              returnedServiceOrder.fix_value_labor,
            ),
            fix_value_final: formatBRValue(
              returnedServiceOrder.fix_value_final,
            ),
            fix_payment_method: returnedServiceOrder.fix_payment_method,
          }}
          onSubmit={handleSubmit}
        >
          <StatusArea>
            <strong>Status: </strong>
            <Select name="status">
              <option value={String(ReturnedServiceOrderStatus.PENDING)}>
                Pendente
              </option>
              <option value={String(ReturnedServiceOrderStatus.FIXED)}>
                Consertada
              </option>
              <option
                value={String(ReturnedServiceOrderStatus.CLIENT_NOTIFIED)}
              >
                Cliente Notificado
              </option>
              <option
                value={String(
                  ReturnedServiceOrderStatus.PRODUCT_DELIVERED_WITHOUT_PAYMENT,
                )}
              >
                Produto Entregue Sem Recebimento
              </option>
              <option
                value={String(ReturnedServiceOrderStatus.BUDGET_NOT_APPROVED)}
              >
                Orçamento Não Aprovado
              </option>
              <option
                value={String(ReturnedServiceOrderStatus.PRODUCT_NOT_DEFECTIVE)}
              >
                Produto Sem Defeito
              </option>
              <option
                value={String(ReturnedServiceOrderStatus.BUDGET_NOT_WORTH_IT)}
              >
                Não Compensa
              </option>
              <option
                value={String(ReturnedServiceOrderStatus.PARTS_NOT_AVAILABLE)}
              >
                Sem Peças de Reposição
              </option>
            </Select>
          </StatusArea>

          <section className="return-data">
            <strong>Informações do Retorno</strong>

            <div className="reason">
              <p>Motivo do Retorno</p>
              <Input
                name="return_motive"
                containerStyle={{
                  width: '876px',
                }}
                type="text"
              />
            </div>
          </section>

          <section className="repair-data">
            <strong>Informações do Novo Conserto</strong>

            <div className="repair-form">
              <div>
                <div className="repair">
                  <div className="name">
                    <p>Técnico</p>
                    <Input
                      name="fix_technician_name"
                      containerStyle={{
                        width: '200px',
                        marginRight: '16px',
                      }}
                      type="text"
                    />
                  </div>
                  <div className="date">
                    <p>Data</p>
                    <Input
                      name="fix_date"
                      containerStyle={{
                        width: '200px',
                      }}
                      type="text"
                    />
                  </div>
                </div>

                <div className="details">
                  <p>Peças</p>
                  <TextArea
                    name="fix_description"
                    containerStyle={{
                      width: '438px',
                      marginBottom: '16px',
                      height: '190px',
                    }}
                    textAreaStyle={{
                      height: '180px',
                    }}
                    type="text"
                  />
                </div>
              </div>

              <div className="prices">
                <div className="price1">
                  <p>Valor das Peças</p>
                  <Input
                    name="fix_value_pieces"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="price2">
                  <p>Mão de Obra</p>
                  <Input
                    name="fix_value_labor"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="price3">
                  <p>Valor Final</p>
                  <Input
                    name="fix_value_final"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
                <div className="payment">
                  <p>Forma de Pagamento</p>
                  <Input
                    name="fix_payment_method"
                    containerStyle={{
                      width: '200px',
                      marginTop: '8px',
                    }}
                    type="text"
                  />
                </div>
              </div>
            </div>

            <button type="submit">Salvar</button>
          </section>
        </Form>
      </Content>
    </Container>
  );
}

export default UpdateReturnedOS;
