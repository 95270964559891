import ReturnedServiceOrderStatus from './ReturnedServiceOrderStatus';

const STATUS = {
  [ReturnedServiceOrderStatus.PENDING]: 'PENDENTE',
  [ReturnedServiceOrderStatus.FIXED]: 'CONSERTADA',
  [ReturnedServiceOrderStatus.CLIENT_NOTIFIED]: 'CLIENTE NOTIFICADO',
  [ReturnedServiceOrderStatus.PRODUCT_DELIVERED_WITHOUT_PAYMENT]: 'PRODUTO ENTREGUE SEM RECEBIMENTO',
  [ReturnedServiceOrderStatus.CANCELED]: 'CANCELADA',
  [ReturnedServiceOrderStatus.COMPLETED]: 'FINALIZADA',
  [ReturnedServiceOrderStatus.BUDGET_NOT_APPROVED]: 'ORÇAMENTO NÃO APROVADO',
  [ReturnedServiceOrderStatus.PRODUCT_NOT_DEFECTIVE]: 'PRODUTO SEM DEFEITO',
  [ReturnedServiceOrderStatus.BUDGET_NOT_WORTH_IT]: 'NÃO COMPENSA',
  [ReturnedServiceOrderStatus.PARTS_NOT_AVAILABLE]: 'SEM PEÇAS DE REPOSIÇÃO',
};

export default function getStatusStringFromInt(statusInt) {
  try {
    const statusStr = STATUS[statusInt];
    return statusStr;
  } catch (err) {
    return '';
  }
}
