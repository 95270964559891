import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  background: transparent;
  border-radius: 10px;
  border: 2px solid #091021;
  color: #091021;
  margin-top: 8px;

  padding: 0 8px;

  width: 230px;
  height: 30px;

  & + textarea {
    margin-top: 8px;
  }

  &::placeholder {
    color: #666368;
  }

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) => props.isErrored
    && css`
      border-color: #c53030;
    `}

  ${(props) => props.isFocused
    && css`
      color: #091021;
      border-color: #666368;
    `}

  ${(props) => props.isFilled
    && css`
      color: #091021;
    `}

  textarea {
    flex: 1;
    background: transparent;
    border: 0;
    color: #091021;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-right: 16px;
  margin-left: 8x;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
