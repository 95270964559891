import React, { useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';
import isManager from '../../utils/isManager';
import api from '../../services/api';

import { Container, Content, User } from './styles';

import MenuBar from '../../components/MenuBar';
import Input from '../../components/Input';
import Select from '../../components/Select';
import toast from 'react-hot-toast';

const createUserStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #091021',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '270px',
    height: '420px',
  },
  selector: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  input: {
    background: 'transparent',
    borderRadius: '10px',
    border: '2px solid #091021',
    color: '#091021',
    marginTop: '8px',

    padding: '0 8px',
    width: '230px',
    height: '30px',
    marginBottom: '8px',
  },
  createButton: {
    background: '#091021',
    color: '#ffff',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    padding: '0 16px',
  },
  cancelButton: {
    background: 'transparent',
    color: '#091021',
    border: '2px solid #091021',
    borderRadius: '10px',
    height: '35px',
    marginTop: '16px',
    marginLeft: '90px',
    padding: '0 16px',
  },
}));

function Configuration() {
  const { signOut, user, updateUser } = useAuth();
  const history = useNavigate();
  const formRef = useRef(null);

  const loggedUserIsManager = useMemo(
    () => isManager(user.authority_level),
    [user.authority_level],
  );

  // Modal que CRIA usuarios ///////////////////////////////////////////////////////////
  const createUserClasses = createUserStyles();
  const [openCreateUserModal, setOpenCreateUserModal] = React.useState(false);

  const handleOpenCreateUserModal = () => {
    setOpenCreateUserModal(true);
  };
  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
  };

  const handleCreateUser = useCallback(async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        user_option: Yup.string().required(),
        new_user_name: Yup.string().required('Nome Obrigatório'),
        new_user_password: Yup.string().required('Senha Obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      const {
        user_option,
        origin_option,
        new_user_name,
        new_user_password,
        admin_password,
      } = data;

      if (user_option === 'gerente') {
        if (origin_option === 'matriz') {
          await api.post('/users', {
            admin_password,
            origin: 100,
            authority_level: 100,
            username: new_user_name,
            password: new_user_password,
          });
        } else if (origin_option === 'filial') {
          await api.post('/users', {
            admin_password,
            origin: 200,
            authority_level: 100,
            username: new_user_name,
            password: new_user_password,
          });
        }
      } else if (user_option === 'funcionario') {
        if (origin_option === 'matriz') {
          await api.post('/employees', {
            origin: 100,
            username: new_user_name,
            password: new_user_password,
          });
        } else if (origin_option === 'filial') {
          await api.post('/employees', {
            origin: 200,
            username: new_user_name,
            password: new_user_password,
          });
        }
      }

      setOpenCreateUserModal(false);

      toast.success('Usuário criado com sucesso!');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current.setErrors(errors);

        return;
      }

      toast.error('Ocorreu um erro ao criar o usuário');
    }
  }, []);

  const handleUpdateUser = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          username: Yup.string().required('Nome obrigatório'),
          password_old: Yup.string(),
          password_new: Yup.string().when('password_old', {
            is: val => val && val.length > 0,
            then: () =>
              Yup.string().required('Campo obrigatório para troca de senha'),
            otherwise: () => Yup.string(),
          }),
          password_confirm: Yup.string().when('password_new', {
            is: val => val && val.length > 0,
            then: () =>
              Yup.string()
                .required('Campo obrigatório para troca de senha')
                .oneOf([Yup.ref('password_new')], 'Confirmação incorreta'),
            otherwise: () => Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          (data.password_new || data.password_confirm) &&
          !data.password_old
        ) {
          toast.error(
            'Campos incompletos. Para atualizar sua senha, é necessário informar a senha atual.',
          );
          return;
        }

        const formData = {
          username: data.username,
          ...(data.password_old
            ? {
                password_old: data.password_old,
                password_new: data.password_new,
                password_confirm: data.password_confirm,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);
        updateUser(response.data);

        history('/consulta');

        toast.success('Perfil atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

          return;
        }

        toast.error('Ocorreu um erro ao atualizar o perfil, tente novamente');
      }
    },
    [history, updateUser],
  );

  const handleLogout = useCallback(async () => {
    history('/');
    await signOut();
  }, [history, signOut]);

  return (
    <Container>
      <MenuBar />

      <div>
        <Modal
          className={createUserClasses.modal}
          open={openCreateUserModal}
          onClose={handleCloseCreateUserModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCreateUserModal}>
            <div className={createUserClasses.paper}>
              <h2>
                <b>Criar Usuário</b>
              </h2>
              <br />

              <Form ref={formRef} onSubmit={handleCreateUser}>
                <div>
                  <p>Tipo: </p>
                  <Select
                    name="user_option"
                    className={createUserClasses.selector}
                  >
                    <option value="gerente">Gerente</option>
                    <option value="funcionario">Funcionário</option>
                  </Select>
                  <p>Origem: </p>
                  <Select
                    name="origin_option"
                    className={createUserClasses.selector}
                  >
                    <option value="matriz">Matriz</option>
                    <option value="filial">Filial</option>
                  </Select>
                  <p>User: </p>
                  <Input
                    name="new_user_name"
                    className={createUserClasses.input}
                    type="text"
                  />
                  <p style={{ marginTop: '8px' }}>Senha: </p>
                  <Input
                    name="new_user_password"
                    className={createUserClasses.input}
                    type="password"
                  />
                  <p style={{ marginTop: '8px' }}>
                    Senha Admin (para criar Gerente):{' '}
                  </p>
                  <Input
                    name="admin_password"
                    className={createUserClasses.input}
                    type="password"
                  />
                </div>

                <button
                  type="submit"
                  className={createUserClasses.createButton}
                >
                  Criar
                </button>
                <button
                  onClick={handleCloseCreateUserModal}
                  type="button"
                  className={createUserClasses.cancelButton}
                >
                  Cancelar
                </button>
              </Form>
            </div>
          </Fade>
        </Modal>
      </div>

      <Content>
        <h1>Configurações</h1>

        {loggedUserIsManager && (
          <button type="button" onClick={handleOpenCreateUserModal}>
            Criar Usuário
          </button>
        )}

        <User>
          <h2>Meus dados</h2>
          <Form
            ref={formRef}
            initialData={{
              username: user.username,
            }}
            onSubmit={handleUpdateUser}
          >
            <Input type="text" name="username" />
            <Input
              type="password"
              name="password_old"
              placeholder="Senha Atual"
            />
            <Input
              type="password"
              name="password_new"
              placeholder="Nova senha"
            />
            <Input
              type="password"
              name="password_confirm"
              placeholder="Confirmar senha"
            />

            <button type="submit">Salvar</button>
          </Form>
        </User>

        <button onClick={handleLogout} type="button">
          Sair
        </button>
      </Content>
    </Container>
  );
}

export default Configuration;
