import TransactionTypes from './TransactionTypes';

export default function formatSignedTransactionValue(transactionTypeInt, transactionValue) {
  if (transactionValue) {
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(transactionValue);

    if ([TransactionTypes.OUTFLOW_PURCHASE].includes(transactionTypeInt)) {
      return `- ${formattedValue}`;
    }

    return formattedValue;
  }
  return '';
}
