import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;

  h1 {
    margin: 24px;
    font-weight: bold;
  }
`;

export const FormsArea = styled.div`
    width: 895px;
    padding: 0 24px 24px 24px;

    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 2px solid #091021;

    form {
      width: 895px;

      align-items: center;
      justify-content: center;

      input {
        border: none;
        padding: 2px 8px;
      }

      .search {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
      }

      .search button {
        margin-top: 50px;
        margin-left: 16px;
      }

      .number p {
        font-weight: bold;
        margin-top: 24px;
      }

      .number input {
        width: 400px;
        margin-right: 16px;
      }


      .return-data {
        margin-top: 24px;
        border-top: 2px solid #091021;
        padding-top: 24px;
      }

      .return-data strong {
        font-weight: bold;
        font-size: 24px;
      }

      .return-data p {
        margin-top: 16px;
      }

      .return-data input {
        border: none;
      padding: 2px 8px;
      }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;


      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }
`;

export const SelectedOS = styled.div`
  width: 860px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  > button {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 100px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    margin-left: 70px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;

export const OSS = styled.div``;

export const OS = styled.div`
  width: 860px;
  margin-top: 16px;
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 16px;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  strong {
    font-size: 18px;
    font-weight: bold;
  }

  > button {
    background: transparent;
    border-radius: 10px;
    height: 30px;
    padding: 0 16px;
    width: 100px;
    color: #091021;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s;
    border: 2px solid #091021;
    margin-left: 70px;

    &:hover {
      background: #091021;
      color: #ffff;
    }
  }
`;
