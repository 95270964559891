import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0px;
  background: #091021;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 56px 0;

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;

      .icon {
        color: #ffff;
        margin-left: 10px;
      }

      li {
        display: inline;
        position: relative;

        a {
          padding: 2px 60px;
          display: inline-block;
          align-items: center;
          font-size: 24px;
          font-weight: bold;

          /* visual do link */
          color: #ffff;
          text-decoration: none;
        }

        > ul {
          position: absolute;
          top: 25px;
          left: 0;
          width: 200px;
          background-color: #091021;
          display: none;

          > li {
            display:block;
            margin-top: 16px;
            margin-bottom: 16px;

            > a {
              font-size: 16px;
              font-weight: normal;
              padding: 0 20px;

            }
          }
        }
      }

      .status-list-ul {
        width: 300px;
      }

      li:hover ul, li.over ul {
        display: block;
      }
    }
  }
`;
