import NormalServiceOrderStatus from './NormalServiceOrderStatus';

const STATUS = {
  [NormalServiceOrderStatus.BUDGET_PENDING]: 'PENDENTE DE ORÇAMENTO',
  [NormalServiceOrderStatus.BUDGET_CREATED]: 'ORÇAMENTO CRIADO',
  [NormalServiceOrderStatus.BUDGET_APPROVED]: 'ORÇAMENTO APROVADO',
  [NormalServiceOrderStatus.PARTS_PENDING]: 'PENDENTE DE PEÇAS',
  [NormalServiceOrderStatus.FIXED]: 'CONSERTADA',
  [NormalServiceOrderStatus.CLIENT_NOTIFIED]: 'CLIENTE NOTIFICADO',
  [NormalServiceOrderStatus.PRODUCT_DELIVERED_WITHOUT_PAYMENT]: 'PRODUTO ENTREGUE SEM RECEBIMENTO',
  [NormalServiceOrderStatus.CANCELED]: 'CANCELADA',
  [NormalServiceOrderStatus.COMPLETED]: 'FINALIZADA',
  [NormalServiceOrderStatus.BUDGET_NOT_APPROVED]: 'ORÇAMENTO NÃO APROVADO',
  [NormalServiceOrderStatus.PRODUCT_NOT_DEFECTIVE]: 'PRODUTO SEM DEFEITO',
  [NormalServiceOrderStatus.BUDGET_NOT_WORTH_IT]: 'NÃO COMPENSA',
  [NormalServiceOrderStatus.PARTS_NOT_AVAILABLE]: 'SEM PEÇAS DE REPOSIÇÃO',
};

export default function getStatusStringFromInt(statusInt) {
  try {
    const statusStr = STATUS[statusInt];
    return statusStr;
  } catch (err) {
    return '';
  }
}
