import React, { useRef, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import getNormalSOStatusStringFromInt from '../../utils/getNormalSOStatusStringFromInt';
import NormalServiceOrderStatus from '../../utils/NormalServiceOrderStatus';
import api from '../../services/api';

import Select from '../../components/Select';
import MenuBar from '../../components/MenuBar';

import { Container, Content, OS } from './styles';

function ListNormalOS() {
  const formRef = useRef(null);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);
  const [serviceOrders, setServiceOrders] = useState([]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setHasSearchedBefore(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          status: Yup.string()
            .required('Selecione um status para buscar'),
        });

        await schema.validate(data, { abortEarly: false });

        const { status } = data;

        const response = await api.get('/service-orders/normal', {
          params: {
            status,
          },
        });

        const serviceOrdersData = response.data;

        setServiceOrders(serviceOrdersData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        // addToast({
        //   type: 'error',
        //   title: 'Erro ao etc.',
        //   description:
        //     'Ocorreu um erro etc.',
        // });
      }
    },
    [],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Listar O.S. Normais</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select name="status">
            <option value={String(NormalServiceOrderStatus.BUDGET_PENDING)}>
              Pendentes de Orçamento
            </option>
            <option value={String(NormalServiceOrderStatus.BUDGET_CREATED)}>
              Orçamento Criado
            </option>
            <option value={String(NormalServiceOrderStatus.BUDGET_APPROVED)}>
              Orçamento Aprovado
            </option>
            <option value={String(NormalServiceOrderStatus.PARTS_PENDING)}>
              Pendentes de Peça
            </option>
            <option value={String(NormalServiceOrderStatus.FIXED)}>
              Consertadas
            </option>
            <option value={String(NormalServiceOrderStatus.CLIENT_NOTIFIED)}>
              Cliente Notificado
            </option>
            <option value={String(NormalServiceOrderStatus.PRODUCT_DELIVERED_WITHOUT_PAYMENT)}>
              Produto Entregue Sem Recebimento
            </option>
            <option value={String(NormalServiceOrderStatus.BUDGET_NOT_APPROVED)}>
              Orçamento Não Aprovado
            </option>
            <option value={String(NormalServiceOrderStatus.PRODUCT_NOT_DEFECTIVE)}>
              Produto Sem Defeito
            </option>
            <option value={String(NormalServiceOrderStatus.BUDGET_NOT_WORTH_IT)}>
              Não Compensa
            </option>
            <option value={String(NormalServiceOrderStatus.PARTS_NOT_AVAILABLE)}>
              Sem Peças de Reposição
            </option>
            <option value={String(NormalServiceOrderStatus.CANCELED)}>
              Canceladas
            </option>
            <option value={String(NormalServiceOrderStatus.COMPLETED)}>
              Finalizadas
            </option>
          </Select>
          <button type="submit">Listar</button>
        </Form>

        {serviceOrders && serviceOrders.length > 0 ? (
          serviceOrders.map((serviceOrder) => (
            <OS key={serviceOrder.id}>
              <strong>{serviceOrder.number}</strong>
              <p>{serviceOrder.client.name}</p>
              <p>{serviceOrder.product_name}</p>
              <p>
                Status:
                {' '}
                {getNormalSOStatusStringFromInt(serviceOrder.status)}
              </p>
              <Link to={`/os/${serviceOrder.id}`}>Abrir</Link>
            </OS>
          ))
        ) : hasSearchedBefore && <p>Nenhuma O.S. foi encontrada.</p>}
      </Content>
    </Container>
  );
}

export default ListNormalOS;
