import React, { useRef, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import getOrderStatusStringFromInt from '../../utils/getOrderStatusStringFromInt';
import OrderStatus from '../../utils/OrderStatus';
import api from '../../services/api';

import Select from '../../components/Select';
import MenuBar from '../../components/MenuBar';

import { Container, Content, Order } from './styles';

function ListOrders() {
  const formRef = useRef(null);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);
  const [orders, setOrders] = useState([]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setHasSearchedBefore(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          status: Yup.string()
            .required('Selecione um status para buscar'),
        });

        await schema.validate(data, { abortEarly: false });

        const { status } = data;

        const response = await api.get('/orders', {
          params: {
            status,
          },
        });

        const ordersData = response.data;

        setOrders(ordersData);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          // return;
        }

        // addToast({
        //   type: 'error',
        //   title: 'Erro ao etc.',
        //   description:
        //     'Ocorreu um erro etc.',
        // });
      }
    },
    [],
  );

  return (
    <Container>
      <MenuBar />
      <Content>
        <h1>Listar Pedidos</h1>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select name="status">
            <option value={String(OrderStatus.PENDING)}>
              Aguardando pedido
            </option>
            <option value={String(OrderStatus.CLIENT_NOTIFIED)}>
              Cliente Notificado
            </option>
            <option value={String(OrderStatus.PIECE_DELIVERED_WITHOUT_PAYMENT)}>
              Pedido Entregue Sem Recebimento
            </option>
            <option value={String(OrderStatus.CANCELED)}>
              Cancelado
            </option>
            <option value={String(OrderStatus.COMPLETED)}>
              Finalizado
            </option>
          </Select>
          <button type="submit">Listar</button>
        </Form>

        {orders && orders.length > 0 ? (
          orders.map((order) => (
            <Order key={order.id}>
              <strong>{order.number}</strong>
              <p>{order.client.name}</p>
              <p>{order.piece_name}</p>
              <p>
                Status:
                {' '}
                {getOrderStatusStringFromInt(order.status)}
              </p>
              <Link to={`/pedido/${order.id}`}>Abrir</Link>
            </Order>
          ))
        ) : hasSearchedBefore && <p>Nenhum pedido foi encontrado.</p>}
      </Content>
    </Container>
  );
}

export default ListOrders;
