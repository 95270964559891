import ExternalServiceOrderStatus from './ExternalServiceOrderStatus';

const STATUS = {
  [ExternalServiceOrderStatus.PENDING]: 'PENDENTE',
  [ExternalServiceOrderStatus.COMPLETED]: 'FINALIZADA',
  [ExternalServiceOrderStatus.CANCELED]: 'CANCELADA',
};

export default function getStatusStringFromInt(statusInt) {
  try {
    const statusStr = STATUS[statusInt];
    return statusStr;
  } catch (err) {
    return '';
  }
}
