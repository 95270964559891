import TransactionTypes from './TransactionTypes';

export default function isInflowTransaction(transactionType) {
  return ([
    TransactionTypes.INFLOW_UPFRONT_PAYMENT,
    TransactionTypes.INFLOW_COMPLETED_SERVICE_ORDER,
    TransactionTypes.INFLOW_COMPLETED_ORDER,
    TransactionTypes.INFLOW_SALE,
  ].includes(transactionType));
}
