const ReturnedServiceOrderStatus = Object.freeze({
  PENDING: 200,
  FIXED: 201,
  CLIENT_NOTIFIED: 202,
  PRODUCT_DELIVERED_WITHOUT_PAYMENT: 203,
  CANCELED: 204,
  COMPLETED: 205,
  BUDGET_NOT_APPROVED: 206,
  PRODUCT_NOT_DEFECTIVE: 207,
  BUDGET_NOT_WORTH_IT: 208,
  PARTS_NOT_AVAILABLE: 209,
});

export default ReturnedServiceOrderStatus;
