import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  h1 {
    margin: 24px;
    font-weight: bold;
  }

  form {
    width: 895px;
    padding: 0 24px 24px 24px;

    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 2px solid #091021;

    .client-data {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 2px solid #091021;
    }

    .client-data p {
      font-weight: bold;
      margin-top: 16px;
    }

    .client-data .name-email {
      display: flex;
      flex-direction: row;
    }

    .client-data .cpf-phones {
      display: flex;
      flex-direction: row;
    }

    .client-address {
      margin-bottom: 24px;
    }

    .client-address p {
      margin-top: 16px;
    }

    .client-address strong {
      font-weight: bold;
      font-size: 24px;
    }

    .client-address .cep-street-number {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .client-address .search-zip-code {
      margin: 0;
    }

    .client-address .search-zip-code button {
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      margin-top: 40px;
      margin-right: 20px;
    }

    .client-address .search-zip-code svg {
      color: #091021;
      margin: 0;
    }

    .client-address .district-city-state {
      display: flex;
      flex-direction: row;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
    }

    .buttons button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 3px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }

    .buttons .delete-button {
      background: #c53030;
      border: 3px solid #c53030;

      width: 140px;
      margin-left: 16px;

      &:hover {
        background: transparent;
        color: #c53030;
      }
    }
  }
`;

export const NameInputStyle = {
  width: '500px',
  marginRight: '16px',
};

export const EmailInputStyle = {
  width: '340px',
};

export const CPFInputStyle = {
  width: '175px',
  marginRight: '16px',
};

export const CNPJInputStyle = {
  width: '175px',
  marginRight: '34px',
};

export const Phone1InputStyle = {
  width: '120px',
  marginRight: '16px',
};

export const Phone2InputStyle = {
  width: '120px',
  marginRight: '16px',
};

export const Phone3InputStyle = {
  width: '120px',
};

export const NeighborhoodInputStyle = {
  width: '250px',
  marginRight: '16px',
};

export const CityInputStyle = {
  width: '200px',
  marginRight: '16px',
};

export const StateInputStyle = {
  width: '70px',
  marginRight: '16px',
};

export const ComplementInputStyle = {
  width: '250px',
};
