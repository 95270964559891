import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 24px;
    font-weight: bold;
  }

  button {
      background: transparent;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 150px;
      color: #091021;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 2px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;

      margin-top: 8px;
      margin-bottom: 8px;

      &:hover {
        background: #091021;
        color: #fff;
      }
    }

`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 16px 24px;
  padding: 16px 24px;

  border: 2px solid #091021;
  border-radius: 10px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
      background: transparent;
      border: none;
      color: #091021;

      padding: 0 8px;

    }

    button {
      background: #091021;
      border-radius: 10px;
      height: 30px;
      padding: 0 16px;
      width: 100px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: background-color 0.2s;
      border: 2px solid #091021;

      align-items: center;
      justify-content: center;
      display: flex;

      margin-top: 16px;

      &:hover {
        background: transparent;
        color: #091021;
      }
    }
  }

  h2 {
    margin-bottom: 8px;
    font-size: 20px;
  }

`;
