import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

const Select = ({
  name, children, ...rest
}) => {
  const selectRef = useRef(null);
  const {
    fieldName, defaultValue, registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <select
      defaultValue={defaultValue}
      ref={selectRef}
      classNamePrefix="react-select"
      {...rest}
    >
      {children}
    </select>
  );
};

export default Select;
